import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import utils from "../../utils/utils";
import PreRecording from "../PreRecording/PreRecording";
import AlloTextContainer from "../AlloText/AlloText";
import AlloMicro from "../AlloMicro/AlloMicro";
import "./Brand.css";
import ReactLoading from "react-loading";

const platform = require("platform");

function PopUp({ choices, text, type }) {
  const [display, setDisplay] = useState(true);
  const elem = useRef();

  const handleChoice = (x) => {
    window.analytics.track(type + " Given", {
      ...window.common_event_property,
      ...{
        reason: x,
      },
    });
    elem.current.style.opacity = 0;
    setTimeout(() => setDisplay(false), 200);
  };

  if (display) {
    return (
      <div ref={elem} className="askWhyText">
        <p className="title">{text}</p>
        <div className="choices">
          {choices.map((v, i) => (
            <div
              key={i}
              onClick={() => handleChoice(v["en"])}
              className="choice"
            >
              <p>{utils.getLanguage(v)}</p>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function AskWhyText() {
  return PopUp({
    choices: [
      {
        en: "I find it easier to write.",
        fr: "Je trouve cela plus facile d'écrire",
      },
      {
        en: "I don't want someone to listen to me",
        fr: "Je n'ai pas envie de pouvoir être écouté",
      },
      {
        en: "I'm not in a place where I can speak",
        fr: "Je suis dans un endroit qui ne me permet pas de parler",
      },
      {
        en: "The recording didn't work",
        fr: "L’enregistrement n’a pas fonctionné",
      },
      {
        en: "Other",
        fr: "Autre",
      },
    ],
    text: (
      <>
        {utils.getLanguage({
          en: "Help us improve your experience !",
          fr: "Aidez nous à améliorer votre expèrience !",
        })}{" "}
        <br />
        {utils.getLanguage({
          en: "Why did you choose writing over speaking ?",
          fr: "Pourquoi avez vous choisi d'écrire plutôt que de parler ?",
        })}
      </>
    ),
    type: "TextReason",
  });
}

function Brand() {
  const { brandId } = useParams();
  const [brandData, setBrandData] = useState(false);
  const [filename, setFilename] = useState("");
  const [canal, setCanal] = useState("audio");
  const [askWhyText, setAskWhyText] = useState(false);

  const switchTextAudio = () => {
    window.analytics.track(
      (canal === "audio" ? "ToText" : "ToAudio") + " Switched",
      {
        ...window.common_event_property,
        ...{
          filename: filename,
        },
      }
    );
    setCanal(canal === "audio" ? "text" : "audio");
  };

  useEffect(() => {
    const fileName =
      utils.makeid(15) +
      "_" +
      (utils.AWS.config.credentials.identityId.replace(":", "-") ||
        "anonymous");
    window.analytics.track("Page Opened", {
      user: utils.AWS.config.credentials.identityId,
      version: process.env.REACT_APP_VERSION,
    });

    const rand =
      localStorage.getItem("rand") ||
      localStorage.setItem("rand", 1 + Math.floor(Math.random() * 2)) ||
      localStorage.getItem("rand");
    // if (brandId === "sncf_sud" && [
    //     "tron-marseille-aubagne",
    //     "tron-aubagne-toulon",
    //     "tron-lesarcs-toulon",
    //     "tron-hyeres-toulon",
    //     "tron-marseille-aix-pertuis"
    // ].includes(utils.getParams()['poc'])) {
    //     window.location.href = "https://webapp.alloreview.com/page/" + brandId + "?" + window.location.href.split("?")[1]
    // }
    if (brandId === "sncf_est_toussncf") {
      window.location.href =
        "https://webapp.alloreview.com/page/sncf_est_toussncf";
    }
    if (brandId === "sncf_est") {
      window.location.href = "https://webapp.alloreview.com/page/sncf_est";
    }
    if (
      window.location.href.includes("sncf_sud?poc=tron-marseille-aubagne-train")
    ) {
      window.location.href = "https://webapp.alloreview.com/page/sncf_est";
    }
    if (
      brandId === "sncf_est" &&
      ["generique"].includes(utils.getParams()["poc"])
    ) {
      window.location.href =
        "https://webapp.alloreview.com/page/" +
        brandId +
        "?" +
        window.location.href.split("?")[1];
    }
    if (brandId === "sncf_occitanie_train_jaune") {
      window.location.href =
        "https://webapp.alloreview.com/page/" +
        brandId +
        "?" +
        window.location.href.split("?")[1];
    }
    async function loadBrand(setter) {
      return utils
        .fetchBrandData(brandId)
        .then((data) => {
          setter(data);
          window.brand = data["brand"];
          window.common_event_property = {
            brand: data["brand"],
            user: utils.AWS.config.credentials.identityId,
            version: process.env.REACT_APP_VERSION,
            url_parameter: utils.getParams(),
            navigator: platform.name,
            navigator_version: platform.version,
            device: platform.product,
            layout: platform.layout,
            os: platform.os,
            manufacturer: platform.manufacturer,
          };
          window.analytics.page(
            "BrandPage",
            data["brand"],
            window.common_event_property
          );
          if ("text-color" in data) {
            document.body.style.color = data["text-color"];
          }

          window.analytics.track(
            "BrandPage Loaded",
            window.common_event_property
          );
        })
        .catch((err) => {
          // Redirect to a link
          console.log(brandId);
          if (brandId) {
            window.location.href =
              "https://webapp.alloreview.com/page/" +
              brandId +
              "?" +
              window.location.href.split("?")[1];
            console.log("Error", err);
          }
        });
    }
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        window.analytics.track("Page CameBack", {
          ...window.common_event_property,
          ...{
            filename: fileName,
          },
        });
      } else {
        window.analytics.track("Page Left", {
          ...window.common_event_property,
          ...{
            filename: fileName,
          },
        });
      }
    });
    window.addEventListener("beforeunload", function (e) {
      window.analytics.track("Page Closed", {
        ...window.common_event_property,
        ...{
          filename: fileName,
        },
      });
    });
    loadBrand(setBrandData);
    window.fileName = fileName;
    setFilename(fileName);
  }, [brandId]);

  if (brandData) {
    //<div className="brand_page" style={{background: "linear-gradient(" + brandData['color'] + ", " + utils.drkColor(brandData['color'], -50) + ")"}}>
    return (
      <div className="brand_page" style={{ background: brandData["color"] }}>
        {askWhyText ? (
          <AskWhyText logActivity={(x) => utils.logActivity(x, filename)} />
        ) : (
          <></>
        )}
        <div
          style={{
            background:
              "gradient" in brandData && !brandData["gradient"]
                ? "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)"
                : "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8)",
            width: "100%",
            height: "inherit",
          }}
        >
          <div className="brand_container">
            <div className="brand_header">
              <img
                src={brandData["image"].replace(
                  "./",
                  process.env.PUBLIC_URL + "/"
                )}
                style={{
                  maxWidth: brandData["image_width"],
                  height: "auto",
                  maxHeight: "100%",
                }}
              />
            </div>
            {canal == "audio" ? (
              <AlloMicro
                brandData={brandData}
                filename={filename}
                switchTextAudio={switchTextAudio}
              />
            ) : (
              <AlloTextContainer
                brandData={brandData}
                filename={filename}
                switchTextAudio={switchTextAudio}
                askWhyText={
                  brandData.hasOwnProperty("askWhyText") &&
                  brandData["askWhyText"]
                    ? () => setAskWhyText(true)
                    : () => true
                }
              />
            )}
            <div
              className="post_module"
              style={{
                marginTop: "100px",
                display: utils.getParams().hasOwnProperty("back_url")
                  ? ""
                  : "none",
              }}
            >
              <p className="label_title">
                <a
                  href={utils.getParams()["back_url"]}
                  style={{ color: "white" }}
                >
                  Retour
                </a>
              </p>
            </div>
            <p className="footer_text">Powered by AlloReview</p>
          </div>
        </div>
        {brandData.hasOwnProperty("pre_modules") ? (
          <PreRecording
            brandData={brandData}
            filename={filename}
            setBrandData={setBrandData}
          />
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <ReactLoading
          type="spin"
          color="rgba(255, 255, 255, 0.4)"
          height={60}
          width={60}
        />
      </div>
    );
  }
}

export default Brand;
