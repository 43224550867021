import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.onerror = function(error, url, line) {
  console.log("CATCHED", process.env.REACT_APP_BASE_API)
  fetch(
    process.env.REACT_APP_BASE_API + '/log_errors',
    {
        method: 'POST',
        body: JSON.stringify({
            error: error,
            url: url,
            url: line
        })
    }
  ).then(
    x => x.json().then(console.warn)
  ).catch(
    x => console.warn("RESULT", x)
  )
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
