import { useEffect, useRef, useState } from 'react'
import utils from '../../utils/utils'
import Recorder from './recorder-azure'
import './Micro.css'
import ReactLoading from 'react-loading';

const isVisitorFrench = () => {
    const lgs = navigator.languages.map(
        x => x.substring(0, 2)
    )
    return lgs.includes('fr')
}

const visitorFrench = isVisitorFrench()

const recorder = new Recorder({
    onRecording: function() {},
    visualizer: true
});

function AltText({content, style, className, delay}) {
    const elem = useRef();
    const [text, setText] = useState('');
    const [displayedText, setDisplayedText] = useState('');

    delay = 4000

    useEffect(() => {
        let changeTimeout;
        const changeText = (i) => {
            let temp_text = "";
            let j = 0;
            while (temp_text.length == 0 && j < content.length) {
                temp_text = utils.getLanguage(content[i])[0].props.children
                i+=1
                i = i % content.length
                j += 1
            }
            setText(temp_text)
            changeTimeout = setTimeout(() => changeText(i), delay)
        }
        changeText(0)
        return () => {clearTimeout(changeTimeout)}
    }, [content, delay])

    useEffect(() => {
        elem.current.style.transition = 'transform 0.2s ease, opacity 0.2s ease'
        let changeTimeout;
        async function transit_text() {
            elem.current.style.transform = 'scale(0)'
            elem.current.style.opacity = '0'
            return new Promise(res => {
                changeTimeout = setTimeout(
                    () => {
                        elem.current.style.transform = 'scale(1)'
                        elem.current.style.opacity = '1'
                        setDisplayedText(text)
                    },
                    200
                )
            })
        }
        transit_text()
        return () => clearTimeout(changeTimeout)
    }, [text])

    return (
        <p className={className} style={style} ref={elem}>{displayedText}</p>
    )
}

function Micro({parentHandler, logActivity, brandData}) {
    const [recordingState, setRecordingState] = useState('pre')
    const [micData, setMicData] = useState({});
    const microButton = useRef();
    const stopButton = useRef();

    useEffect(() => {
        parentHandler(
            recordingState,
            micData
        )
    }, [recordingState, micData])

    // useEffect(() => {
    //     microButton.current.addEventListener('touchstart', e => {
    //         different_states[recordingState]['fonction']();
    //         e.preventDefault();
    //     })
    //     microButton.current.addEventListener('mousedown', different_states[recordingState]['fonction'])
    // }, [recordingState, microButton])

    useEffect(() => {
        microButton.current.src = visitorFrench ? process.env.PUBLIC_URL + "/images_micro/micro_fr.svg" : process.env.PUBLIC_URL + "/images_micro/micro_en.svg"
        stopButton.current.src = visitorFrench ? process.env.PUBLIC_URL + "/images_micro/stop_fr.svg" : process.env.PUBLIC_URL + "/images_micro/stop_en.svg"
    }, [microButton, stopButton])

    const resetRecording = () => {
        microButton.current.style.display = 'block'
        stopButton.current.style.display = 'none'
        microButton.current.classList.remove("disappear")
        stopButton.current.classList.remove("appear")
        setRecordingState('pre');
    }

    const startRecording = () => {
        microButton.current.classList.add("disappear")
        const timeout = setTimeout(
            () => {
                microButton.current.style.display = 'none'
                stopButton.current.style.display = 'block'
                stopButton.current.classList.add("appear")
            },
            200
        )
        recorder.toggleRecording().then(
            () => window.analytics.track("Microphone Accessed", {
                ...window.common_event_property
            })
        ).catch(x => {
            if (String(x).includes('Permission denied')) {
                window.analytics.track("Microphone AccessRefused", {
                    ...window.common_event_property
                })
            } else {
                window.analytics.track("Microphone AccessErrored", {
                    ...window.common_event_property,
                    ...{
                        "error": String(x)
                    }
                })
            } 
            clearTimeout(timeout)
            resetRecording();
        })
        
        return setRecordingState('recording');
    } 

    const stopRecording = () => {
        window.analytics.track("Recording Stopped", {
            ...window.common_event_property
        })
        setRecordingState('loading');
        return recorder.toggleRecording().then( x => {
            setRecordingState('post');
            setMicData(x);
        }).catch(
            x => {
                window.analytics.track("Recognition Errored", {
                    ...window.common_event_property,
                    "error": x
                })
                resetRecording();
            }
        );
    } 
    
    const different_states = {
        "pre": {
            "class": "pre_micro",
            "fonction": startRecording,
            "text": [
                brandData['autres']['pre_micro'] || {
                    "en": "Press to record yourself",
                    "fr": "Appuyez pour vous enregistrer",
                    "ar-BH": "إضغط ثم تكلم",
                    "de": "Berühren und dann sprechen",
                    "ko": "터치하고 말하기"
                }
            ]
        },
        "recording": {
            "class": "recording_micro",
            "fonction": stopRecording,
            "text": [
                brandData['autres']['recording_micro'] || {
                    "en": "Press to stop the recording!",
                    "fr": "Appuyez pour terminer l'enregistrement",
                    "ar-BH": "المس للإرسال!",
                    "de": "Berühren Sie, um zu senden!",
                    "ko": "보내려면 터치하세요!"
                }
            ]
        },
        "loading": {
            "class": "loading_micro",
            "fonction": () => {},
            "text": [
                brandData['autres']['loading_micro'] || {
                    "en": "Please wait, analysis in progress ...",
                    "fr": "Veuillez patienter, analyse en cours ...",
                    "ar-BH": "التحليل قيد التقدم ...",
                    "de": "Analyse läuft ...",
                    "ko": "분석 진행 중 ..."
                }
            ]
        },
        "post": {
            "class": "loading_micro",
            "fonction": () => {},
            "text": [
                brandData['autres']['loading_micro_2'] || {
                    "en": "Please wait, sending in progress ...",
                    "fr": "Veuillez patienter, envoi en cours ...",
                    "ar-BH": "جاري الإرسال ...",
                    "de": "Senden in Bearbeitung ...",
                    "ko": "전송 중 ..."
                }
            ]
        }
    }

    if (!['fransat', 'BBSE_b'].includes(window.location.pathname.split('/').slice(-1)[0])) {
        different_states['pre']['text'].push(
            brandData['autres']['confidentiel'] || {
                "en": "Don't worry, your voice will stay confidential",
                "fr": "Rassurez-vous, votre voix restera confidentielle",
                "ar-BH": "",
                "de": "",
                "ko": ""
            }
        )
    }

    return (
        <div>
            <div className="micro_container">
                <img ref={microButton} onClick={different_states[recordingState]['fonction']} className={"micro_img " + different_states[recordingState]['class']} src="/images_micro/micro_fr.svg"/>
                <img onClick={different_states[recordingState]['fonction']} ref={stopButton} className={"stop_img " + different_states[recordingState]['class']} src="/images_micro/stop_fr.svg"/>
                <div style={{
                    display: ['post', 'loading'].includes(recordingState) ? 'block' : 'none',
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "80px",
                    height: "130px"
                }}>
                    <ReactLoading type="spinningBubbles" color="white" height={80} width={80} />
                </div>
                <div className={"wave_container " + (recordingState === 'recording' ? 'wave_appear' : '')}>
                    <div className="wave1"></div>
                    <div className="wave2"></div>
                </div>
            </div>
            <AltText className="micro_label" content={different_states[recordingState]['text']} />
        </div>
    );
}
  
  export default Micro;