import { useEffect, useRef, useState } from "react";
import SelectSearch from 'react-select-search';
import {fuzzySearch} from 'react-select-search';
import utils from '../../utils/utils';
import './PostRecording.css';
import './select-search.css'

const ga = require('../../utils/gtag')

function ThankYouModule({texts, sentiment}) {
    const self = useRef()
    const titleElem = useRef();
    const textElem = useRef();

    useEffect(
        () => {
            window.scroll(0, 0)
            titleElem.current.style.fontSize = utils.getFontSize(utils.getLanguage(texts['thanks']).length, 36);
            textElem.current.style.fontSize = utils.getFontSize(utils.getLanguage(texts['contextual'][sentiment]).length, 28, 0.05);
            document.getElementsByClassName("brand_header")[0].classList.add('smaller_header')
        }
    )

    const getSmiley = sent => process.env.PUBLIC_URL + '/smiley/' + sent + '.svg'
    return(
        <div ref={self} className="post_module thank_you">
            <p ref={titleElem} className="thanks">{utils.getLanguage(texts['thanks'])}</p>
            {
                ("smiley" in texts && !texts['smiley']) ? '' : <img src={getSmiley(sentiment)}/>}
            <p ref={textElem}>{utils.getLanguage(texts['contextual'][sentiment])}</p>
        </div>
    )
}

function EmailModule({text, filename, brand, color, sentiment}) {
    const self = useRef();
    const field = useRef();
    const [email, setEmail] = useState('') 
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
    })

    useEffect(() => {

        if (!text.hasOwnProperty("type")) {
            const changePlaceholder = (i) => {
                const placeholders = ['john@doe.com', '+33 6 12 34 56 78']
                const j = i === 0 ? 1 : 0;
                field.current.placeholder = placeholders[i]
                setTimeout(
                    () => changePlaceholder(j),
                    3000
                )
            }
            changePlaceholder(1)
        } else if (text['type'] == "email") {
            field.current.placeholder = 'john@doe.com'
        }
    }, [color])

    const sendEmail = () => {
            window.analytics.track('EmailButton Pressed', {
                ...window.common_event_property,
                ...{
                    filename: filename
                }
            });
            utils.updatefield(brand, filename, 'email', field.current.value)
            
            const elem = self.current;
            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
    }


    const disp = text['only'] == undefined || text['only'].includes(sentiment)

    return(
        <div ref={self} className="post_module" style={{display: disp ? "" : "none"}}>
            <p className="label_title">{utils.getLanguage(text)}</p>
            <div className="email_input">
                <input value={email} onChange={e => setEmail(e.target.value)} ref={field} type="text" placeholder="john@doe.com"/>
            </div>
            <p className="send_button" style={{
                display: text['type'] != "email" || regexEmail.test(String(email).toLowerCase()) ? 'none' : '',
                opacity: 0
            }}></p>
            <p onClick={sendEmail} className="send_button" style={{
                color: color,
                display: text['type'] != "email" || regexEmail.test(String(email).toLowerCase()) ? '' : 'none'
            }}>{
                utils.getLanguage({
                    "en": "Send",
                    "fr": "Envoyer",
                    "ko": "보내다"
                })
            }</p>
        </div>
    )
}

const RatingModule = ({text, filename, brand, sentiment}) => {
    const [rating, setRating] = useState(0);
    const self = useRef()

    const shouldDisplay = text.hasOwnProperty('only') ? (sentiment == text['only'] ? '' : 'none') : '';

    useEffect(() => {
        if (text['prefil_star']) {
            if (sentiment === "POSITIVE") {
                setRating(5)
            }
            if (sentiment === "NEGATIVE") {
                setRating(2)
            }
            if (sentiment === "MIXED") {
                setRating(3)
            }
        }
    }, [])

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
    }, [rating])

    const giveStar = x => {
        const elem = self.current;
        utils.updatefield(brand, filename, 'rating', x)
        elem.style.transform = 'scale(0)'
        elem.style.height = '0'
        elem.style.opacity = '0'
        setRating(x)
    }

    return (
        <div ref={self} className="post_module" style={{display: shouldDisplay}}>
            <p className="label_title">{utils.getLanguage(text)}</p>
            <div className="star_container">
                {[1, 2, 3, 4, 5].map(val => 
                    <div className='rating_star' key={val} onClick={() => giveStar(val)}>
                        <svg viewBox="0 0 100 100" style={{
                            fill: rating >= val ? 'rgba(255, 222, 138, 0.7)' : 'rgba(255, 255, 255, 0)' 
                        }}>
                            <polygon points="50,9 60.5,39.5 92.7,40.1 67,59.5 76.4,90.3 50,71.9 23.6,90.3 32.9,59.5 7.2,40.1 39.4,39.5"/>
                        </svg>
                    </div>
                )}
            </div>
        </div>
    )
}

const YesNo = ({yesNoData, filename, brand}) => {
    const [answer, setAnswer] = useState('');
    const self = useRef()

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        if (answer.length > 0) {
            utils.updatefield(brand, filename, 'answer', answer)
            const data = {
                'field': 'sat_score',
                'value': answer,
            }
            setAnswer('')
            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
        }
    }, [answer, brand, filename])

    return (
        <div ref={self} className="pre_module">
            <p className="label_title">{utils.getLanguage(yesNoData['title'])}</p>
            <div className="satisfaction_container">
                {yesNoData["choices"].map((val, i) => <p className='satisfaction_choice' key={i} onClick={() => setAnswer(val['value'])}>{utils.getLanguage(val['text'])}</p>
                )}
            </div>
        </div>
    )
}

const SatisfactionModule = ({ratingData, filename, brand}) => {
    const [rating, setRating] = useState(0);
    const [text, setText] = useState(utils.getLanguage(ratingData['text']));
    const self = useRef()
    const ratingModule = useRef()

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        if (rating != 0) {
            utils.updatefield(brand, filename, 'rating', rating)
            const data = {
                'field': 'sat_score',
                'value': rating,
            }
            if (ratingData.hasOwnProperty("modifications") && ratingData["modifications"].hasOwnProperty(String(rating))) {
                data["modifications"] = ratingData["modifications"][String(rating)]
            }
            setRating(0)
            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
        }
    }, [rating, brand, filename])

    return (
        <div ref={self} className="pre_module">
            <p className="label_title">{text}</p>
            <div ref={ratingModule} className="satisfaction_container">
                {ratingData["choices"].map((val, i) => <p className='satisfaction_choice' key={i} onClick={() => setRating(val['value'])}>{val['image'] == undefined ? '' : <><img src={val['image']} width="40px" style={{
                    verticalAlign: "middle"
                }}/><br/></>}{utils.getLanguage(val['text'])}</p>
                )}
            </div>
        </div>
    )
}

const PromoterScoreModule = ({text, filename, brand, color}) => {
    const [rating, setRating] = useState(0);
    const self = useRef()

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        if (rating > 0) {
            utils.updatefield(brand, filename, 'promoter_score', rating)

            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
        }
    }, [rating])

    return (
        <div ref={self} className="post_module">
            <p className="label_title">{text}</p>
            <div className="star_container">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(val => 
                    <div style={{
                        backgroundColor: color ? color[val] : '',
                        color: color ? "black" : "white"
                    }} className='rating_bubble' key={val} onClick={() => setRating(val)}>
                        {val}
                    </div>
                )}
            </div>
        </div>
    )
}

const Form = ({data, filename, brand, color}) => {
    const [parameter, setParameters] = useState({})
    const self = useRef()

    const send = () => {
        let fields_to_update = Object.keys(parameter)

        const updatefield_seq = ({brand, filename, params})  => {
            return utils.updatefield(brand, filename, params[0], parameter[params[0]]).then(
                () => params.slice(1).length > 0 ? updatefield_seq({brand: brand, filename: filename, params: params.slice(1)}) : true
            )
        }
        
        updatefield_seq({brand: brand, filename: filename, params: fields_to_update})

        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        elem.style.transform = 'scale(0)'
        elem.style.height = '0'
        elem.style.opacity = '0'
    }

    const inputs = {
        "text": ({placeholder, text, field}) => <div>
            <p>{utils.getLanguage(text)}</p>
            <input placeholder={placeholder} onChange={e => {
                const temp = {...parameter}
                temp[field] = e.target.value
                setParameters(temp)
            }}/>
        </div>,
        "long_select": ({choices, text, field}) => <div>
            <p>{utils.getLanguage(text)}</p>
            <SelectSearch search filterOptions={fuzzySearch} options={
                typeof(choices[0]) == "string" ?
                    choices.map(v => {return {value: v, name: v}}) :
                    choices.map(v => {return {value: v['value'], name: v['text']}})
            } onChange={e => {
                const temp = {...parameter}
                temp[field] = e
                setParameters(temp)
            }} placeholder=""/>
        </div>,
        "text_long":  ({placeholder, text, field}) => <div>
            <p>{utils.getLanguage(text)}</p>
            <textarea placeholder={utils.getLanguage(placeholder, true)} onChange={e => {
                const temp = {...parameter}
                temp[field] = e.target.value
                setParameters(temp)
            }}></textarea>
        </div>
    }

    return (
        <div className="post_module" ref={self}>
            <p className="label_title">{utils.getLanguage(data['text']['title'])}</p>
            <div className="poc_selector">
                {data['inputs'].map(
                    (v, i) => <div key={i} style={{
                        display: i > 0 ? (parameter.hasOwnProperty(data['inputs'][i - 1]["field"]) ? '' : 'none') : ''
                    }}>
                        {inputs[v["type"]](v)}
                    </div>
                )}
                <p className="send_button" onClick={send} style={{color: color, display: parameter.hasOwnProperty(data['inputs'].slice(-1)[0]["field"]) ? '' : 'none'}}>{
                    utils.getLanguage({
                        "en": "Send",
                        "fr": "Envoyer",
                        "ko": "보내다"
                    })
                }</p>
            </div>
        </div>
    )
}

const GoogleReviewModule = ({url, color, transcript, brandId, sentiment, mustFilter}) => {
    mustFilter = mustFilter == undefined ? true : mustFilter;

    console.log(transcript)
    if (mustFilter && sentiment != "POSITIVE") {
        return ('')
    }
    return (
        <div className="post_module">
            <p className="label_title">
                {
                    utils.getLanguage({
                        "fr": "Votre avis a été copié dans le presse papier, vous pouvez le coller dans Google Review :",
                        "en": "Your review has been copied to the clipboard, you can paste it into Google Review :",
                        "ko": "리뷰가 클립 보드에 복사되었습니다. Google 리뷰에 붙여 넣을 수 있습니다."
                    })
                }
            </p>
            <a style={{color: color}} className="google_button" onClick={() => {navigator.clipboard.writeText(transcript);
            window.analytics.track('GoogleReviewButton Pressed', {
                ...window.common_event_property
            });setTimeout(() => window.open(url), 100);}} target="_blank"> Google Review </a>
        </div>
    )
}

const ShareReviewModule = ({params, color, transcript, sentiment}) => {
    const mustFilter = params.filter == undefined ? true : params.filter;

    const urlsObj = {
        "urlGoogleReview": {
            "name": "GoogleReview"
        },
        "urlTrustpilot": {
            "name": "Trustpilot"
        },
        "urlTwitter": {
            "name": "Twitter",
            "function": (x, txt) => x + encodeURIComponent(txt)
        },
        "urlFacebook": {
            "name": "Facebook"
        },
    }

    if (mustFilter && sentiment != "POSITIVE") {
        return ('')
    }
    return (
        <div className="post_module">
            <p className="label_title">
                {
                    utils.getLanguage(params.text)
                }
            </p>
            {
                Object.keys(urlsObj).map(
                    k => <a key={k} style={{color: color, marginBottom: "10px"}} className="google_button" onClick={() => {navigator.clipboard.writeText(transcript);
                    window.analytics.track('GoogleReviewButton Pressed', {
                        ...window.common_event_property
                    });setTimeout(() => window.open(urlsObj[k]["function"] ? urlsObj[k]["function"](params[k], transcript)  : params[k]), 100);}} target="_blank"> {urlsObj[k]["name"]} </a>
                )
            }
        </div>
    )
}

const AgreementModule = ({agreementText, buttons, color, brand, filename}) => {
    const self = useRef();

    const setAgreement = v => {
        utils.updatefield(brand, filename, 'agreement', v)
            
        const elem = self.current;
        elem.style.transform = 'scale(0)'
        elem.style.height = '0'
        elem.style.opacity = '0'
    }

    return (
        <div ref={self} className="post_module">
            <p className="label_title">
                {
                    utils.getLanguage(agreementText)
                }
            </p>
            {
                buttons.map(
                    (v, i) => <a key={i} style={{color: color}} onClick={() => setAgreement(v['value'])} className="google_button"> {utils.getLanguage(v['text'])} </a>
                )
            }
        </div>
    )
}

function PostRecording({brandData, data}) {
    const [iAppear, setIAppear] = useState(0)

    useEffect(() => {
        if (!brandData.hasOwnProperty('filename')) {
            brandData['filename'] = data['file_id']
        }
    }, [data])

    useEffect(() => {
        const t = iAppear == 0 ? 50 : 600;
        if (iAppear <= Object.keys(brandData['post_modules']).length) {
            setTimeout(() => {
                setIAppear(iAppear+1)
            }, 600)
        }
    }, [iAppear])

    const modules = [() => ThankYouModule({texts:brandData['text']['post'], sentiment:data['sentiment']})];

    const getModules = {
        'rating': () => RatingModule({text:brandData['post_modules']['rating'], filename: brandData['filename'], brand: brandData['brand'], sentiment: data['sentiment']}),
        'promoter_score': () => PromoterScoreModule({text:utils.getLanguage(brandData['post_modules']['promoter_score']), filename: brandData['filename'], brand: brandData['brand']}),
        'promoter_score_c': () => PromoterScoreModule({text:utils.getLanguage(brandData['post_modules']['promoter_score_c']['text']), filename: brandData['filename'], brand: brandData['brand'], color: brandData['post_modules']['promoter_score_c']['color']}),
        'email': () => EmailModule ({text:brandData['post_modules']['email'], filename: brandData['filename'], brand: brandData['brand'], color: brandData['color'], sentiment: data['sentiment']}),
        'agreement': () => AgreementModule ({
            agreementText: brandData['post_modules']['agreement']['text'],
            buttons: brandData['post_modules']['agreement']['actions'],
            filename: brandData['filename'],
            brand: brandData['brand'],
            color: brandData['color']
        }),
        "satisfaction": () => SatisfactionModule({
            ratingData:brandData['post_modules']['satisfaction'],
            filename: brandData['filename'],
            brand: brandData['brand']
        }),
        'google_review': () => utils.getParams().hasOwnProperty('pos') && brandData['post_modules']["google_review"]["url"].hasOwnProperty(utils.getParams()['pos']) ?
            GoogleReviewModule({url: brandData['post_modules']["google_review"]["url"][utils.getParams()['pos']], color: brandData['color'], transcript: data['text'], brandId: brandData['brand'], sentiment:data['sentiment'], mustFilter: brandData['post_modules']["google_review"]["mustFilter"]}) :
            GoogleReviewModule({url: brandData['post_modules']["google_review"]["url"], color: brandData['color'], transcript: data['text'], brandId: brandData['brand'], sentiment:data['sentiment'], mustFilter: brandData['post_modules']["google_review"]["mustFilter"]}),
        'share_review': () => ShareReviewModule({params: brandData['post_modules']["share_review"], color: brandData['color'], transcript: data['text'], brandId: brandData['brand'], sentiment:data['sentiment']}),
        'form': () => Form({
            data: brandData['post_modules']['form'],
            filename: brandData['filename'],
            brand: brandData['brand'],
            color: brandData['color']
        }),
        "yesno": () => YesNo({
            yesNoData: brandData['post_modules']['yesno'],
            filename: brandData['filename'],
            brand: brandData['brand'],
        }),
        "button": () => <a style={{display: brandData['post_modules']['button'].hasOwnProperty('only') ? (data['sentiment'] == brandData['post_modules']['button']['only'] ? '' : 'none') : ''}} target="_blank" className="send_button button_post" href={brandData['post_modules']['button']['url']}>{utils.getLanguage(brandData['post_modules']['button']['label'])}</a>
    }

    Object.keys(brandData['post_modules']).forEach(
        v => modules.push(getModules[v])
    )

    const appearStyle = {
        transform: "scale(1)",
        opacity: "1",
    }

    return (
        <div className="post_recording">
            {
                modules.map(
                    (v, i) => (
                        <div key={i} className='module_appear' style={iAppear > i ? appearStyle : {}}>
                            {v()}
                        </div>
                    )
                )
            }
            <div className="post_module" style={{display: utils.getParams().hasOwnProperty('back_url') ? '' : 'none'}}>
                <p className="label_title">
                    <a href={utils.getParams()['back_url']} style={{color: 'white'}}>Retour</a>
                </p>
            </div>
        </div>
    )
}

export default PostRecording;