import AWS from 'aws-sdk'
const ga = require('./gtag')

AWS.config.region = 'eu-west-2';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: 'eu-west-2:56832fd5-9257-456b-a548-ed80d7fe2263',});
AWS.config.credentials.refresh(() => {
    //mixpanel.identify(AWS.config.credentials.identityId);
    window.analytics.identify(AWS.config.credentials.identityId)
    console.log('Identified')
});
window.AWS = AWS

class Utils {
    constructor() {
        this.AWS = AWS;
        window.activity = {
            'device': navigator.userAgent,
            'user': AWS.config.credentials.identityId,
            'events': [],
            'brand': document.location.pathname.split('/').slice(-1)[0]
        }
    }
    
    textContent(elem) {
        if (elem instanceof Array) {
            return elem.map(this.textContent).join('')
        }
        if (!elem) {
          return '';
        }
        if (typeof(elem) === 'string') {
          return elem;
        }
        const children = elem.props && elem.props.children;
        if (children) {
            if (typeof children === 'string') {
                return children
            }
            return children.map(this.textContent).join('');
        }
        return this.textContent(children);
      }

    logActivity(eventName, filename, comment) {
        console.log("EVENT :", eventName)
        ga.ga('Pipeline', eventName, window.activity['brand']);
        window.activity['filename'] = filename
        const event = {
            "eventName": eventName,
            "timestamp": (new Date()).getTime()
        }
        if (comment) {
            event["comment"] = comment
        }
        window.activity['events'].push(event)
        window.activity['timestamp'] = window.activity['events'][0]["timestamp"]
        fetch(
            process.env.REACT_APP_BASE_API + '/log_activity',
            {
                method: 'POST',
                body: JSON.stringify(window.activity)
            }
        )
    }  

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    fetchBrandData(brandId) {
        return fetch(
            process.env.PUBLIC_URL + '/brands/' + brandId + '.json',
            {
                method: "GET",
                cache: "no-store"
            }
        ).then(
            resp => {
                if (!resp.ok) {
                    return new Promise(res => res(resp))
                }
                return resp.json()
            }
        ).then(
            resp => {
                if (resp.hasOwnProperty('image') && resp.hasOwnProperty('color') && resp.hasOwnProperty('brand')) {
                    return new Promise(res => res(resp))
                }
                if (Object.keys(resp).includes(this.getParams()['alt'])) {
                    return new Promise(res => res(resp[this.getParams()['alt']]))
                }
                return fetch(
                    process.env.REACT_APP_BASE_API + '/get_webapp_version',
                    {
                        method: "POST",
                        body: JSON.stringify({...{"brand": brandId}, ...this.getParams()})
                    }
                ).then(
                    resp1 => {
                        if (!resp1.ok) {
                            return new Promise(res => res(resp[Object.keys(resp)[0]]))
                        }
                        return resp1.json()
                    }
                ).then(
                    resp1 => {
                        console.log(resp1)
                        if (resp1.hasOwnProperty('version')) {
                            return new Promise(res => res(resp[resp1['version']]))
                        }
                        return new Promise(res => res(resp[Object.keys(resp)[0]]))
                    }
                ).catch(
                    () => new Promise(res => res(resp[Object.keys(resp)[0]]))
                )
            }
        )
    }

    replaceVariableString = text => {
        const variables = this.getParams()
        
        const matches = text.match(/\{[A-z,1-9,_]*\}/g)
        
        if (matches) {
            for (const match of matches) {
                if (variables.hasOwnProperty(match.substring(1, match.length - 1))) {
                    text = text.replace(
                        match,
                        variables[match.substring(1, match.length - 1)]
                    )
                }
            }
        }
        return text
    }

    getFontSize = (textLength, baseSize, coef) => {
        coef = coef || 0.5;
        const fontSize = baseSize - coef*textLength;
        return `${fontSize}px`
    }
    
    manage_links(text) {
        //console.error('Coucou')
        //console.error('text', text)
        const find = /\[.*?\]\(.*?\)/g
        let parts = text.split(find);
        let matches = text.match(find);
        const result = []
        for(let i = 0; i < parts.length; i++) {
            result.push(<span key={i}>{parts[i]}</span>);
            if (matches && i < matches.length) {
                const params = matches[i].match(/\[(.*?)\]\((.*?)\)/)
                if (params && params.length >=3) {
                    result.push(
                        <a style={{color: "lightblue"}} href={params[2]} target="_blank" key={i + "_link"}>{params[1]}</a>
                    );
                }
            }
        }
        return result;
    }

    getLanguage(dict, onlyText) {
        const manageReplacements = onlyText ? x => x : x => this.manage_links(this.replaceVariableString(x))
        dict = {...dict}
        if (typeof(dict) === "string") {
            return this.replaceVariableString(dict);
        }
        const lgs = navigator.languages.map(
            x => x.substring(0, 2)
        )

        if (this.getParams().hasOwnProperty('lg')) {
            lgs.unshift(this.getParams()['lg'])
        }

        for (const lg of lgs) {
            if (dict.hasOwnProperty(lg)) {
                return manageReplacements(dict[lg])
            }
        }
        return manageReplacements(dict[Object.keys(dict)[0]])
    }

    drkColor(colorCode, amount) {
        var usePound = false;
     
        if (colorCode[0] == "#") {
            colorCode = colorCode.slice(1);
            usePound = true;
        }
     
        var num = parseInt(colorCode, 16);
     
        var r = (num >> 16) + amount;
     
        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
            return "#000000"
        }
     
        var b = ((num >> 8) & 0x00FF) + amount;
     
        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
            return "#000000"
        }
     
        var g = (num & 0x0000FF) + amount;
     
        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
            return "#000000"
        }
        console.log(("0" + g.toString(16)).slice(-2))
        return (usePound ? "#" : "") + ("0" + (r << 16).toString(16)).slice(-2) + ("0" + g.toString(16)).slice(-2) + ("0" + (b << 8).toString(16)).slice(-2);
    }

    getParams () {
        var params = {};
        var parser = document.createElement('a');
        const url = document.URL
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    sendAudio (audioData, brand, filename) {
        const timestamp = Date.now()
        const file_id = filename;

        const feedbackData = { ...audioData['audioData'] }

        feedbackData['id'] = file_id;
        feedbackData['timestamp'] = timestamp;
        feedbackData['results'] = {'transcripts': [{'transcript': feedbackData['text']}]}
        feedbackData['user'] = AWS.config.credentials.identityId;
        feedbackData['Predominant_Sentiment_Comprehend'] = feedbackData['sentiment']
        feedbackData['alternative-languages'] = [feedbackData['language_alt']]
        if (this.getParams().hasOwnProperty('is_test')) {
            feedbackData['is_test'] = true
        }
        delete feedbackData['sentiment']
        delete feedbackData['language_alt']
        if (window.custom_models) {
            feedbackData['custom-model'] = window.custom_models
        }

        const urlParameters = this.getParams();
        Object.keys(urlParameters).map(
            x => {
                x = x.toLocaleLowerCase()
                if (['email', 'rating', 'pointofsale'].includes(x)) {
                    feedbackData[x] = urlParameters[x];
                } else if (x.substring(0, 2) == 'c_') {
                    feedbackData['custom:' + x.substring(2)] = urlParameters[x];
                } else if (x.substring(0, 9) == 'airtable_') {
                    feedbackData['airtable:' + x.substring(9)] = urlParameters[x];
                } else if (x == 'poc') {
                    feedbackData['poc'] = urlParameters[x].split('_');
                } else if (x == 'pos') {
                    if (feedbackData['poc']) {
                        feedbackData['poc'] = Array.concat(
                            urlParameters[x].split('_'),
                            feedbackData['poc']
                        )
                    } else {
                        feedbackData['poc'] = urlParameters[x].split('_');
                    }
                }
            }
        );

        return new Promise ( res => fetch(
            process.env.REACT_APP_BASE_API + '/GetPresignedUrl',
            {
                method: 'POST',
                body: JSON.stringify({
                    brand: brand,
                    filename: file_id
                })
            }
        ).then(
            x => {
                if (x.status === 400) {
                  return x.json().then(console.warn)
                }
                return x.json()
              }
        ).then(
            resp => {
                // Send the file json
                console.warn(JSON.stringify(feedbackData))
                const file = new File(
                    [
                        new Blob([
                            JSON.stringify(feedbackData)
                        ])
                    ],
                    file_id + '.json'
                )
                const formData = new FormData();

                for (const key in resp['metadata_url'].fields) {
                    formData.append(key, resp['metadata_url'].fields[key]);
                };
                formData.append('file', file);

                fetch(
                    resp['metadata_url'].url,
                    {
                        method: 'POST',
                        body: formData
                    }
                ).then(x => 
                    {
                        window.analytics.track("Transctipt Received", {
                            ...window.common_event_property,
                            ...{
                                "status": x.status,
                                "filename": filename
                            }
                        })
                        res(file_id)
                    }
                )
            }
        ));
    }

    updatefield(brand, filename, fieldName, fieldValue) {
        console.log(filename)
        return fetch(
            process.env.REACT_APP_BASE_API + '/edit_feedback_field',
            {
                method: 'POST',
                body: JSON.stringify({
                    brand: brand,
                    filename: filename + '.json',
                    field: fieldName,
                    value: fieldValue
                })
            }
        ).then(
            resp => resp.json()
        )
    }
}

const utils = new Utils();

export default utils;