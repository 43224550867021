/* eslint-disable */
$ = require('jquery')
ga_obj = {
    'ga': () => console.log('ga not loaded')
}

$(() => {
    console.log('ga loaded')
    ga_obj.ga = (val1, val2, val3) => {
        if ("ga" in window &&  "getAll" in ga) {
            tracker = ga.getAll()[0];
            if (tracker)
                tracker.send('event', val1, val2, val3);
        }
    }
})
module.exports = ga_obj