import { useState, useEffect, useRef } from "react";
import PostRecording from '../PostRecording/PostRecording';
import {AlloText} from 'allo-micro';
import utils from '../../utils/utils';

function MainTexts({content}) {
    const elem = useRef();
    const [text, setText] = useState('');

    useEffect(() =>  {
        elem.current.style.fontSize = Math.min(1.2*Math.sqrt((elem.current.clientHeight*elem.current.clientWidth)/utils.textContent(text).length), 40) + "px"
    }, [text])

    useEffect(() => {
        elem.current.style.transition = 'transform 0.2s ease, opacity 0.2s ease'
        async function transit_text() {
            elem.current.style.transform = 'scale(0)'
            elem.current.style.opacity = '0'
            return new Promise(res => setTimeout(
                () => {
                    if (elem.current) {  
                        elem.current.style.transform = 'scale(1)'
                        elem.current.style.opacity = '1'
                        setText(content)
                    } 
                },
                200
            ))
        }
        transit_text()
    }, [content])

    return (
        <p className="main_texts" ref={elem}><span>{text}</span></p>
    )
}

const AlloTextContainer = ({brandData, filename, switchTextAudio, askWhyText}) => {
    const [postRecording, setPostRecording] = useState(false);
    const [micData, setMicData] = useState({});
    const [preText, setPreText] = useState('catch_phrase');

    switchTextAudio = switchTextAudio || (() => true)

    const handlePost = x => {
        setMicData(x);
        window.analytics.track('Feedback Sent', {
            ...window.common_event_property,
            ...{
                "filename": filename,
                "filesize": window.audio_file_size,
                "type": "text"
            }
        });
        askWhyText()
        setPostRecording(true);
    }
    
    useEffect(() => {
        if (brandData["text-color"]) {
            document.getElementsByTagName("textarea")[0].style.color = brandData["text-color"]
            document.getElementsByTagName("textarea")[0].style.fontWeight = "400"
            var styleSheet = document.createElement("style")
            styleSheet.type = "text/css"
            styleSheet.innerText = "textarea::placeholder{color:" + brandData["text-color"] + ";}"
            document.head.appendChild(styleSheet)
        }

        let i = 0;
        const switchText = setInterval(
            () => {
                if (utils.getLanguage(brandData['text']["pre"]['explaination'])[0].props.children.length > 0) {
                    const ts = ['explaination', 'catch_phrase']
                    setPreText(ts[i]);
                    i = (i + 1)%2;
                }
            },
            4000
        )
        return () => clearInterval(switchText)
    }, [])
    
    if (!postRecording) {
        const urlParameters = utils.getParams();
        const additional_parameter = {}
        Object.keys(urlParameters).map(
            x => {
                x = x.toLocaleLowerCase()
                if (['email', 'rating', 'pointofsale'].includes(x)) {
                    additional_parameter[x] = urlParameters[x];
                } else if (x.substring(0, 2) == 'c_') {
                    additional_parameter['custom:' + x.substring(2)] = urlParameters[x];
                } else if (x.substring(0, 9) == 'airtable_') {
                    additional_parameter['airtable:' + x.substring(9)] = urlParameters[x];
                } else if (x == 'poc') {
                    additional_parameter['poc'] = urlParameters[x].split('_');
                } else if (x == 'pos') {
                    if (additional_parameter['poc']) {
                        additional_parameter['poc'] = Array.concat(
                            urlParameters[x].split('_'),
                            additional_parameter['poc']
                        )
                    } else {
                        additional_parameter['poc'] = urlParameters[x].split('_');
                    }
                }
            }
        );
        return (<>
            <MainTexts content={utils.getLanguage(brandData['text']["pre"][preText])} />
            <div style={{width: "400px", maxWidth: "80%", marginLeft: 'auto', marginRight: 'auto'}}>
                <AlloText
                    brand={brandData["brand"]}
                    color="grey"
                    language='fr'
                    user={window.AWS.config.credentials.identityId}
                    filename={filename}
                    handleEnd={x => handlePost(x)}
                    env={process.env.REACT_APP_ENV}
                    custom={additional_parameter}
                />
            </div>
            <p onClick={switchTextAudio} style={{
                fontStyle: "italic",
                textDecoration: "underline",
                fontSize: "20px",
                cursor: "pointer"
            }}>{utils.getLanguage({
                "en": "I'd prefer to speak !",
                "fr": "Je préfère parler !"
            })}</p>
        </>)
    } else {
        return <PostRecording brandData={brandData} data={micData}/>
    }
}

export default AlloTextContainer;