import {
    Link
  } from "react-router-dom";
import { useEffect, useState } from 'react';
import './Home.css'


function BrandCard({color, image, brandName}) {
    return (
        <Link className="BrandCard" to={'./brand/' + brandName} style={{backgroundColor: color}}>
            <img alt="brand" src={image}/>
        </Link>
    )
}

function Home() {
    const [brands, setBrands] = useState([])

    useEffect(
        () => {
            fetch('./homepage.json').then(
                resp => resp.json()
            ).then(
                resp => {
                    Promise.all(
                        resp['brands'].map(
                            brandName => fetch('./brands/' + brandName + '.json').then(
                                    resp1 => resp1.json()
                                ).then(
                                    resp1 => {
                                        if (resp1.hasOwnProperty('image') && resp1.hasOwnProperty('color') && resp1.hasOwnProperty('brand')) {
                                            return {
                                                'image': resp1['image'],
                                                'color': resp1['color'],
                                                'brand': resp1['brand']
                                            }
                                        }
                                        return {
                                            'image': resp1[Object.keys(resp1)[0]]['image'],
                                            'color': resp1[Object.keys(resp1)[0]]['color'],
                                            'brand': resp1[Object.keys(resp1)[0]]['brand']
                                        }
                                    }
                                )
                        )
                    ).then(newBrands => setBrands(newBrands))
                }
            )
        }
    , [])

    return(
        <div>
            <div className="HomeHeader">
                <p>AlloReview</p>
            </div>
            <div className="BrandContainers" style={{overflowY: 'scroll', maxHeight: 'calc(100vh - 60px)'}}>
                {
                    brands.map(
                        (v, k) => <BrandCard key={k} color={v['color']} image={v['image']} brandName={v['brand']}/>
                    )
                }
            </div>
        </div>
    )
}

export default Home;