import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import React from "react";
import Brand from './components/Brand/Brand'
import Home from './components/Home/Home'
import './App.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log("CATCHED", process.env.REACT_APP_BASE_API)
    // fetch(
    //   process.env.REACT_APP_BASE_API + '/log_errors',
    //   {
    //       method: 'POST',
    //       body: JSON.stringify({
    //           error: error,
    //           errorInfo: errorInfo
    //       })
    //   }
    // ).then(
    //   x => console.warn("RESULT", x)
    // ).catch(
    //   x => console.warn("RESULT", x)
    // )
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter basename="/">
        <div className="App">
            <Switch>
              <Route path="/">
                <Route path="/brand/:brandId">
                  <Brand/>
                </Route>
                <Route path="/home">
                  <Home/>
                </Route>
                <Route exact path="/" render={() => <Redirect to="/home" />} />
              </Route>
            </Switch>
          </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
