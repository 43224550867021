import { useEffect, useRef, useState } from "react";
import Micro from '../Micro/Micro';
import PostRecording from '../PostRecording/PostRecording';

import utils from '../../utils/utils';
import './AlloMicro.css'

function AltText({content, style, className, delay}) {
    const elem = useRef();
    const [text, setText] = useState('');
    const [displayedText, setDisplayedText] = useState('');

    delay = 4000

    useEffect(() => {
        elem.current.style.fontSize = Math.min(1.2*Math.sqrt((elem.current.clientHeight*elem.current.clientWidth)/utils.textContent(displayedText).length), 40) + "px"
    }, [displayedText])

    useEffect(() => {
        let changeTimeout;
        const changeText = (i) => {
            let temp_text = "";
            let j = 0;
            while (utils.textContent(temp_text).length == 0 && j < content.length) {
                temp_text = utils.getLanguage(content[i])[0]
                i+=1
                i = i % content.length
                j += 1
            }
            setText(temp_text)
            changeTimeout = setTimeout(() => changeText(i), delay)
        }
        changeText(0)
        return () => clearTimeout(changeTimeout)
    }, [content, delay])

    useEffect(() => {
        elem.current.style.transition = 'transform 0.2s ease, opacity 0.2s ease'
        let changeTimeout;
        async function transit_text() {
            elem.current.style.transform = 'scale(0)'
            elem.current.style.opacity = '0'
            return new Promise(res => {
                changeTimeout = setTimeout(
                    () => {
                        elem.current.style.transform = 'scale(1)'
                        elem.current.style.opacity = '1'
                        setDisplayedText(text)
                    },
                    200
                )
            })
        }
        transit_text()
        return () => clearTimeout(changeTimeout)
    }, [text])

    return (
        <p className={className} ref={elem}><span>{displayedText}</span></p>
    )
}

function MainTexts({content}) {
    let elem = useRef();
    
    if (content.hasOwnProperty('thanks')){
        content = {
            "fr": ""
        }
    }
    if (content.hasOwnProperty('catch_phrase')) {
        content = Object.values(content)
    } else {
        content = [content]
    }

    return (
        <div ref={elem}><AltText className="main_texts" content={content}/></div>
    )
}

function AlloMicro ({brandData, filename, switchTextAudio}) {
    const [micData, setMicData] = useState({});
    const [recordingState, setRecordingState] = useState('pre');
    const [postRecording, setPostRecording] = useState(false);

    switchTextAudio = switchTextAudio || (() => true)

    const audioData = {};
    window.alt_languages = brandData.hasOwnProperty('languages') ? brandData['languages'] : false;
    window.forced_language = brandData.hasOwnProperty('forced_language') ? brandData['forced_language'] : false;
    window.custom_models = brandData.hasOwnProperty('custom_models') ? brandData['custom_models'] : false;

    useEffect(() => {
        const urlParams = utils.getParams();
        if (urlParams["text"] && urlParams["sentiment"] && urlParams["filename"]) {
            brandData['filename'] = urlParams["filename"]
            setMicData({
                "text": urlParams["text"],
                "sentiment": urlParams["sentiment"]
            })
            setPostRecording(true)
        }
    }, [])

    useEffect(() => {
        async function displayPostRecordingPage () {
            return new Promise(res => {
                setTimeout(() => {
                    setPostRecording(true);
                    res();
                }, 200)
            })
        }
        if (recordingState == 'post') {
            // Function to upload file and audio
            window.analytics.track('Analysis Done', {
                ...window.common_event_property,
                ...{
                    "filename": filename
                }
            });
            if (brandData.hasOwnProperty('data')) {
                audioData['audioData'] = {...audioData['audioData'], ...brandData['data']}
                console.log(audioData)
            }
            
            utils.sendAudio(audioData, brandData['brand'], filename).then(
                x => {
                    brandData['filename'] = x;

                    window.analytics.track('Feedback Sent', {
                        ...window.common_event_property,
                        ...{
                            "filename": filename,
                            "filesize": window.audio_file_size
                        }
                    });
                    displayPostRecordingPage();
                }
            );
        }

        if (recordingState == 'recording') {
            window.analytics.track('RecordButton Pressed', {
                ...window.common_event_property,
                ...{
                    "filename": filename
                }
            });
        }
    }, [recordingState])

    brandData['text']['recording'] = {
        "catch_phrase": brandData['autres']['listening'],
        "explaination": brandData['autres']['explaination'] || {
            "en": "Once finished, touch to send!",
            "fr": "Une fois terminé, touchez pour envoyer !",
            "de": "Wenn Sie fertig sind, berühren Sie, um zu senden!",
        }
    };

    brandData['text']['loading'] = {
        "catch_phrase": {
            "fr": ""
        },
        "explaination": {
            "fr": ""
        }
    };

    brandData['text']['post'] = Object.assign(brandData['text']['post'], brandData['text']['loading']);
    
    const handleMicrophone = (state, data) => {
        setMicData(data);
        console.log(data)
        setRecordingState(state);

        if (state == 'post') {
            audioData['audioData'] = data
        }
    }
    
    const logActivity = x => utils.logActivity(x, filename)

    if (!postRecording) {
        return (
            <div className="microphone">
                <MainTexts content={brandData['text'][recordingState]}/>
                <Micro className={'Micro' + (recordingState === 'post' ? ' MicroEnd' : '')} parentHandler={handleMicrophone} logActivity={logActivity} brandData={brandData}/>
                <p onClick={switchTextAudio} style={{
                    fontStyle: "italic",
                    textDecoration: "underline",
                    fontSize: "20px",
                    cursor: "pointer",
                    marginBottom: "0",
                    display: brandData.hasOwnProperty('allow_text') ?
                        (brandData['allow_text'] ? '' : 'none') :
                        ''
                }}>{utils.getLanguage({
                    "en": "I'd prefer to write !",
                    "fr": "Je préfère écrire !"
                })}</p>
                {
                    recordingState === 'pre' && brandData['text'].hasOwnProperty('cgu') ?
                        <p style={{marginTop: "20px", marginBottom: "0px"}}>{utils.getLanguage(brandData['text']['cgu'])}</p> :
                        ''
                }
            </div>
        )
    } else {
        return(
            <PostRecording brandData={brandData} data={micData}/>
        )
    }
}

export default AlloMicro