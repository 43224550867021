import { useEffect, useRef, useState } from "react";
import utils from '../../utils/utils';
import './PreRecording.css';

const RatingModule = ({ratingData, filename, brand, answerCallback}) => {
    const [rating, setRating] = useState(0);
    const self = useRef()

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        if (rating > 0) {
            //utils.updatefield(brand, filename, 'rating', rating)
            const data = {
                'field': 'rating',
                'value': rating,
            }
            if (ratingData.hasOwnProperty("modifications") && ratingData["modifications"].hasOwnProperty(String(rating))) {
                data["modifications"] = ratingData["modifications"][String(rating)]
            }
            answerCallback(data)
            setRating(0)
            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'
        }
    }, [rating, brand, filename, answerCallback])

    return (
        <div ref={self} className="pre_module">
            <p className="label_title">{utils.getLanguage(ratingData['text'])}</p>
            <div className="star_container">
                {[1, 2, 3, 4, 5].map(val => 
                    <div className='rating_star' key={val} onClick={() => setRating(val)}>
                        <svg viewBox="0 0 100 100" style={{
                            fill: rating >= val ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0)' 
                        }}>
                            <polygon points="50,9 60.5,39.5 92.7,40.1 67,59.5 76.4,90.3 50,71.9 23.6,90.3 32.9,59.5 7.2,40.1 39.4,39.5"/>
                        </svg>
                    </div>
                )}
            </div>
        </div>
    )
}

const SatisfactionModule = ({ratingData, filename, brand, answerCallback}) => {
    const [rating, setRating] = useState(0);
    const [text, setText] = useState(utils.getLanguage(ratingData['text']));
    const self = useRef()
    const ratingModule = useRef()

    useEffect(() => {
        const elem = self.current;
        elem.style.height = elem.clientHeight + 'px';
        if (rating > 0) {
            //utils.updatefield(brand, filename, 'rating', rating)
            const data = {
                'field': 'sat_score',
                'value': rating,
            }
            if (ratingData.hasOwnProperty("modifications") && ratingData["modifications"].hasOwnProperty(String(rating))) {
                data["modifications"] = ratingData["modifications"][String(rating)]
            }
            setRating(0)
            elem.style.transform = 'scale(0)'
            elem.style.height = '0'
            elem.style.opacity = '0'

            if (ratingData.hasOwnProperty("post_message")) {
                setTimeout(() => {
                    setText(utils.getLanguage(ratingData['post_message']))
                    ratingModule.current.style.display = "none"
                    elem.style.transform = 'scale(1)'
                    elem.style.height = 'default'
                    elem.style.opacity = '1'
                    setTimeout(() => {
                       answerCallback(data)
                    }, 4000)
                }, 200)
            } else {
                answerCallback(data)
            }
        }
    }, [rating, brand, filename, answerCallback])

    return (
        <div ref={self} className="pre_module">
            <p className="label_title">{text}</p>
            <div ref={ratingModule} className="satisfaction_container">
                {ratingData["choices"].map((val, i) => <p className='satisfaction_choice' key={i} onClick={() => setRating(val['value'])}>{utils.getLanguage(val['text'])}</p>
                )}
            </div>
        </div>
    )
}

function PreRecording({brandData, filename, setBrandData}) {
    const [nbAnswered, setNbAnswered] = useState(0)
    const self = useRef()

    const addAnswered = (data) => {
        console.log(data)
        const temp = {...brandData}
        if (!temp.hasOwnProperty('data')) {
            temp['data'] = {}
        }
        if (data.hasOwnProperty('field')) {
            temp['data'][data['field']] = data['value']
        }
        if (data.hasOwnProperty('modifications')) {
            temp["text"]["pre"] = data["modifications"]
            console.log(temp)
        }
        setBrandData(temp)
        setNbAnswered(nbAnswered+1)
    }

    const modules = [];

    const getModules = {
        'rating': () => RatingModule({
            ratingData:brandData['pre_modules']['rating'],
            filename: filename,
            brand: brandData['brand'],
            answerCallback: addAnswered
        }),
        "satisfaction": () => SatisfactionModule({
            ratingData:brandData['pre_modules']['satisfaction'],
            filename: filename,
            brand: brandData['brand'],
            answerCallback: addAnswered
        })
    }

    Object.keys(brandData['pre_modules']).forEach(
        v => modules.push(getModules[v])
    )

    useEffect(
        () => {
            if (nbAnswered == modules.length) {
                self.current.style.opacity = 0;
                setTimeout(
                    () => {
                        self.current.style.display = 'none'
                    },
                    500
                )
            }
        },
        [nbAnswered]
    )

    return (
        <div ref={self} className="pre_recording">
            <p className="pre_title pre_module">
                {utils.getLanguage({
                    "en": "Before to start :",
                    "fr": "Avant de commencer :"
                })}
            </p>
            {
                modules.map(
                    (v, i) => (
                        <div key={i}>
                            {v()}
                        </div>
                    )
                )
            }
            <p className="close" onClick={() => setNbAnswered(modules.length)}>
                {utils.getLanguage({
                    "en": "Close",
                    "fr": "Fermer"
                })}
            </p>
        </div>
    )
}

export default PreRecording;