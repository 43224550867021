const sdk = require("microsoft-cognitiveservices-speech-sdk");
const audioUtils = require("./audioUtils")

function getParams () {
    var params = {};
    var parser = document.createElement('a');
    const url = document.URL
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

function Recorder(config) {

    config = config || {};

    var self = this;
    var transcription = "";
    var sampleRate;
    var audioInput;
    var audioNode;
    var bufferSize = config.bufferSize || 4096;
    var recording = false;
    var audioCtx;
    var azure_recognizer;
    var azure_recognizer2 = false;
    var auth_token;
    var language = false;
    var language_alt = '';
    var webSocket;

    this.transcript = ''

    const get_auth_token = () => {
        return fetch(
            'https://xorfrexjm8.execute-api.eu-west-2.amazonaws.com/Prod/get_azure_stt_token',
            {
                method: "GET",
            }
        ).then((response) => {
            if (!response.ok) {
                return console.error("Not able to get a token !")
            }
            return response.json()
        }).then(token => new Promise(res => {
            auth_token = token;
            return res(token);
        }))
    }

    var setting_token = get_auth_token();

    const getLanguage = () => {
        let output = window.alt_languages || ["fr-FR", "en-US", "es-ES"]
        console.log(output)
        // Attention toute les langues ne supporte pas la detection du language
        const all = ["ar-BH", "ar-EG", "ar-IQ", "ar-JO", "ar-KW", "ar-LB", "ar-OM", "ar-QA", "ar-SA", "ar-SY", "ar-AE", "bg-BG", "ca-ES", "zh-HK", "zh-CN", "zh-TW", "hr-HR", "cs-CZ", "da-DK", "nl-NL", "en-US", "en-AU", "en-CA", "en-HK", "en-IN", "en-IE", "en-NZ", "en-PH", "en-SG", "en-ZA", "en-GB", "et-EE", "fi-FI", "fr-FR", "fr-CA", "de-DE", "de-AT", "el-GR", "gu-IN", "hi-IN", "hu-HU", "ga-IE", "it-IT", "ja-JP", "ko-KR", "lv-LV", "lt-LT", "mt-MT", "mr-IN", "nb-NO", "pl-PL", "pt-BR", "pt-PT", "ro-RO", "ru-RU", "sk-SK", "sl-SI", "es-ES", "es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-CU", "es-DO", "es-EC", "es-SV", "es-GT", "es-HN", "es-MX", "es-NI", "es-PA", "es-PY", "es-PE", "es-PR", "es-UY", "es-US", "es-VE", "sv-SE", "ta-IN", "te-IN", "th-TH", "tr-TR"]
        // PEUT mieux avec langauge du nav mettre languages du nav slice 2 devant default
        const all2 = all.map(v => v.substring(0, 2))
        const all2_conv = all.map(v => [v.substring(0, 2), v]).filter((x, i) => all2.indexOf(x[0]) == i)
        const all_conv = {}
        all2_conv.forEach(
            v => all_conv[v[0]] = v[1]
        )
        const lgs = [
            ...(navigator.languages.map(x => all_conv.hasOwnProperty(x) ? all_conv[x] : x)),
            ...output
        ]
        if (getParams().hasOwnProperty('lg')) {
            lgs.unshift(getParams()['lg'])
        }
        output = lgs.filter(
            (x, i, s) => all.includes(x) && s.indexOf(x) == i
        )
        console.log(output.slice(0, 4))
        return output.slice(0, 4)
    }

    const addCustomModel = (lgs) => {
        if (!window.custom_models) {
            return sdk.AutoDetectSourceLanguageConfig.fromLanguages(lgs)
        } else {
            const configs = []
            for (const lg of lgs) {
                if (window.custom_models.hasOwnProperty(lg)) {
                    configs.push(sdk.SourceLanguageConfig.fromLanguage(lg, window.custom_models[lg]))
                } else {
                    configs.push(sdk.SourceLanguageConfig.fromLanguage(lg))
                }
            }
            console.log(configs)
            return sdk.AutoDetectSourceLanguageConfig.fromSourceLanguageConfigs(configs)
        }
    }

    const setRecogniser = (token) => {
        const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(token, "westeurope");
        // A REVOIR POUR INTEGRER LA LANGUE DU NAVIGATEUR
        console.log(token)
        console.log(getLanguage())
        const autoDetectConfig = addCustomModel(getLanguage());
        const autoDetectConfig2 = addCustomModel(getLanguage());
        var pushStream = sdk.AudioInputStream.createPushStream();
        var pushStream2 = sdk.AudioInputStream.createPushStream();
        const audioConfig = sdk.AudioConfig.fromStreamInput(pushStream);
        const audioConfig2 = sdk.AudioConfig.fromStreamInput(pushStream2);
        //speechConfig.speechRecognitionLanguage = 'fr-FR'
        // NULL !!!!
        const all = ["ar-BH", "ar-EG", "ar-IQ", "ar-JO", "ar-KW", "ar-LB", "ar-OM", "ar-QA", "ar-SA", "ar-SY", "ar-AE", "bg-BG", "ca-ES", "zh-HK", "zh-CN", "zh-TW", "hr-HR", "cs-CZ", "da-DK", "nl-NL", "en-US", "en-AU", "en-CA", "en-HK", "en-IN", "en-IE", "en-NZ", "en-PH", "en-SG", "en-ZA", "en-GB", "et-EE", "fi-FI", "fr-FR", "fr-CA", "de-DE", "el-GR", "gu-IN", "hi-IN", "hu-HU", "ga-IE", "it-IT", "ja-JP", "ko-KR", "lv-LV", "lt-LT", "mt-MT", "mr-IN", "nb-NO", "pl-PL", "pt-BR", "pt-PT", "ro-RO", "ru-RU", "sk-SK", "sl-SI", "es-ES", "es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-CU", "es-DO", "es-EC", "es-SV", "es-GT", "es-HN", "es-MX", "es-NI", "es-PA", "es-PY", "es-PE", "es-PR", "es-UY", "es-US", "es-VE", "sv-SE", "ta-IN", "te-IN", "th-TH", "tr-TR"]
        if (getParams()['lg'] || window.forced_language) {
            speechConfig.speechRecognitionLanguage = getParams()['lg'] || window.forced_language;
            azure_recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            console.log(azure_recognizer)
        } else {
            azure_recognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectConfig, audioConfig);
            azure_recognizer2 = sdk.SpeechRecognizer.FromConfig(speechConfig, autoDetectConfig2, audioConfig2);
            console.log(azure_recognizer)
        }
        /*if (keywords.length > 0) {
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(azure_recognizer);
            keywords.forEach(
                v => phraseList.addPhrase(v)
            )
        }*/
        var is_recognizing = false;
        const onRecordingFunct = (rawAudioChunk) => {
            let raw = rawAudioChunk.detail
        
            if (raw == null)
                return;
        
            // downsample and convert the raw audio bytes to PCM
            let downsampledBuffer = audioUtils.downsampleBuffer(raw, sampleRate);
            let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);
            pushStream.write(pcmEncodedBuffer)
            if (webSocket.readyState in [WebSocket.CLOSE, WebSocket.CLOSING]) {
                webSocket.send(JSON.stringify({
                    id: window.fileName,
                    event: "media",
                    sampleRate: sampleRate,
                    pcmBuffer: downsampledBuffer
                }))
            }
            if (is_recognizing) {
                pushStream2.write(pcmEncodedBuffer)
            }
        }

        document.addEventListener('onRecording', onRecordingFunct, false);
    
        azure_recognizer.recognizing = (s, e) => {
            console.log(`RECOGNIZING: Text=${e.result.text}`);
        };
    
        azure_recognizer.recognized = (s, e) => {
            // Pourris aussi
            if (getParams()['lg'] || window.forced_language) {
                language = getParams()['lg'] || window.forced_language
            } else {
                language = sdk.AutoDetectSourceLanguageResult.fromResult(e.result)['privLanguage'];
            }
            console.log(`Finisher, RECOGNIZED: Text=${e.result.text}`);
            if (e.result.text !== undefined) {
                transcription += e.result.text + '\n';
            }
            if (e.result.reason == sdk.ResultReason.RecognizedSpeech) {
                console.log(`RECOGNIZED: Text=${e.result.text}`);
            }
            else if (e.result.reason == sdk.ResultReason.NoMatch) {
                console.log("NOMATCH: Speech could not be recognized.");
            }
        };
    
        azure_recognizer.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);
    
            if (e.reason == sdk.CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you update the subscription info?");
            }
    
            document.removeEventListener('onRecording', onRecordingFunct);
            azure_recognizer.stopContinuousRecognitionAsync();
        };
    
        azure_recognizer.sessionStopped = (s, e) => {
            console.log(e, s)
            console.log("\n    Session stopped event.");
            azure_recognizer.stopContinuousRecognitionAsync();
            this.transcript = transcription;
            document.removeEventListener('onRecording', onRecordingFunct);
        };
        azure_recognizer.startContinuousRecognitionAsync();

        if (azure_recognizer2) {
            setTimeout(() => {
                console.log('----- Start -----')
                console.log(new Date())
                is_recognizing = true
                azure_recognizer2.startContinuousRecognitionAsync()
            }, 3000)
    
            azure_recognizer2.recognizing = (s, e) => {
                // Pourris aussi
                language_alt = e.result['privLanguage']
                console.log(e.result['privLanguage']);
                console.log(new Date());
                azure_recognizer2.stopContinuousRecognitionAsync()
                azure_recognizer2.close();
                is_recognizing = false;
            };
        }    
    }
	
	this.toggleRecording = function() {
        console.log(recording ? "🟥 Stopped recording" : "🔴 Started recording")
		return recording ? self.stop() : self.start();
	}
	

    this.start = function() {
        const webSocketPromise = new Promise(res => {
            webSocket = new WebSocket('wss://record-audio-stream.alloreview.com:5000/');
            webSocket.onopen = () => {
                console.log("🌍 ", "Send start message")
                webSocket.send(JSON.stringify({
                    id: window.fileName,
                    brand: window.brand,
                    event: "start",
                    stage: process.env.REACT_APP_ENV
                }))
                res()
            }
        })
		
        // webkit audio context shim
        audioCtx = new (window.AudioContext || window.webkitAudioContext)();

        if (audioCtx.createJavaScriptNode) {
            audioNode = audioCtx.createJavaScriptNode(bufferSize, 1, 1);
        } else if (audioCtx.createScriptProcessor) {
            audioNode = audioCtx.createScriptProcessor(bufferSize, 1, 1);
        } else {
            throw 'WebAudio not supported!';
        }

        audioNode.connect(audioCtx.destination);

        return webSocketPromise.then(
            () => navigator.mediaDevices.getUserMedia({audio: true}),
            
        ).then((d) => {
                /*.catch(function (error) {
                    console.log('There was an error streaming your audio to Amazon Transcribe. Please try again.');
                    toggleStartStop();
                });*/
                onMicrophoneCaptured(d);
                setting_token.then(() => setRecogniser(auth_token))
                //Part of the transcribe
                
            })//.catch(onMicrophoneError);
    };

    this.stop = function(cancel) {
        return new Promise((res, rej) => {
            if (cancel) {
                console.log('Canceled')
            } else {
                console.log('stopContinuousRecognitionAsync')
            }

            // stop recording
            recording = false;

            // to make sure onaudioprocess stops firing
            window.localStream.getTracks().forEach( (track) => { track.stop(); });
            audioInput.disconnect();
            audioNode.disconnect();

            console.log("🌍 ", "Send stop message")
            webSocket.send(JSON.stringify({
                id: window.fileName,
                event: "stop"
            }))

            if (webSocket.readyState in [WebSocket.CLOSE, WebSocket.CLOSING]) {
                console.log("🌍 ", "Close Socket")
                webSocket.close()
            }
            
            azure_recognizer.stopContinuousRecognitionAsync(() => {
                config.onRecordingStop && config.onRecordingStop();
                if (!language) {
                    return rej({
                        "en": "There was an error with the recognition!",
                        "fr": "Il y a eu une erreur avec la reconnaissance !",
                        "ko": "인식에 실수가있었습니다!"
                    })
                }
                fetch(
                    'https://bdcgo3u5t6.execute-api.eu-west-2.amazonaws.com/prod/',
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            "text": this.transcript,
                            "language": language.substring(0, 2)
                        })
                    }
                ).then(
                    resp => {
                        if (!resp.ok) {
                            transcription = ''
                            res({text:this.transcript, sentiment:'NEUTRAL', language:language, language_alt: language_alt})
                        }
                        return resp.json()
                    }
                ).then(
                    resp => {
                        transcription = ''
                        console.log(resp)
                        res({text:this.transcript, sentiment:resp['sentiment'], language:language, language_alt: language_alt})
                    }
                )
            })
        })
    };
	
	this.upload = function (url, params, callback) {
        var formData = new FormData();
        formData.append("audio", self.blob, config.filename || 'recording.wav');
        
        for (var i in params)
            formData.append(i, params[i]);

        var request = new XMLHttpRequest();
        request.upload.addEventListener("progress", function (e) {
            callback('progress', e, request);
        });
        request.upload.addEventListener("load", function (e) {
            callback('load', e, request);
        });

		request.onreadystatechange = function (e) {
			var status = 'loading';
			if (request.readyState == 4)
			{
				status = request.status == 200 ? 'done' : 'error';
			}
			callback(status, e, request);
		};
  
        request.open("POST", url);
        request.send(formData);
    };

    function onMicrophoneCaptured(microphone) {
		recording = true;
		// save the stream so we can disconnect it when we're done
		window.localStream = microphone;

        audioInput = audioCtx.createMediaStreamSource(microphone);
        
        audioInput.connect(audioNode);
        //console.log(audioInput)
        //audioInput.connect(audioCtx.createGain());

		if (config.visualizer)
            visualize(microphone);
        

        audioNode.onaudioprocess = onAudioProcess;

		self.startDate = new Date();
		
		config.onRecordingStart && config.onRecordingStart();
		sampleRate = audioCtx.sampleRate;
    }

    function onMicrophoneError(e) {
		console.log(e);
		alert('Unable to access the microphone.');
    }

    function onAudioProcess(e) {
        if (!recording) {
            return;
        }
        
        var event = new CustomEvent('onRecording', { 'detail': e.inputBuffer.getChannelData(0) });
        document.dispatchEvent(event);

		self.duration = new Date().getTime() - self.startDate.getTime();

		config.onRecording && config.onRecording(self.duration);
    }

	
	function visualize(stream) {
        var analyser = audioCtx.createAnalyser();
        
		analyser.fftSize = 2048/2;
		var bufferLength = analyser.fftSize;
        var dataArray = new Uint8Array(bufferLength);
        
        var biquadFilter = audioCtx.createBiquadFilter();
        audioInput.connect(biquadFilter);
        biquadFilter.connect(analyser);
        
		analyser.fftSize = 2048/2;
		var bufferLength = analyser.fftSize;
        var dataArray = new Uint8Array(bufferLength);
        
        const draw = () => {
            analyser.getByteTimeDomainData(dataArray);
            let volume1 = 0
            let volume2 = 0
            for (const v in dataArray) {
                if (v > 512) {
                    volume1 += dataArray[v]
                } else {
                    volume2 += dataArray[v]
                }
            }
            volume1 = volume1/512
            volume2 = volume2/512
            const coef = navigator.userAgent.includes('iPhone OS') ? 20 : 5;
            const scale1 = 1 + coef*(1 - volume1/128)
            const scale2 = 1 + coef*(1 - volume2/128)
            if (document.getElementsByClassName('wave1')[0] && document.getElementsByClassName('wave2')[0]) {
                document.getElementsByClassName('wave1')[0].style.transform = 'scale(' + scale1 + ') translate(-50%, -50%)';
                document.getElementsByClassName('wave2')[0].style.transform = 'scale(' + scale2 + ') translate(-50%, -50%)';
            }
            if (!recording) {
                console.log('STOPPED')
                return;
            }
            setTimeout(draw, 100);
        }
        
        draw()
	}
}

export default Recorder;